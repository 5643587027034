<template>
  <v-app id="inspire">
    <v-app-bar app dark color="primary" flat>
      <v-tabs>
        <v-tab v-for="link in links" :key="link.name" :to="link.to">
          {{ link.name }}
        </v-tab>
      </v-tabs>

      <v-badge
        :content="unreadNotifications.length"
        :value="unreadNotifications.length"
        color="red"
        overlap
      >
        <v-btn
          color="#fff"
          @click="$store.commit('notification/setModal', true)"
          icon
          outlined
          large
          dark
        >
          <v-icon large dark>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon class="ml-2" x-large v-on="on">
            <v-avatar color="#AE2CA1" size="48">
              <span class="white--text text-h5 text-capitalize">
                {{ Array.from(user.fullName)[0] }}
              </span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card dark color="primary">
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center px-5">
              <v-avatar color="#AE2CA1">
                <span class="white--text text-h5">{{
                  Array.from(user.fullName)[0]
                }}</span>
              </v-avatar>
              <h3>{{ user.fullName }}</h3>
              <p class="text-caption mt-1">
                {{ user.userName }}
              </p>
              <!--              <v-divider class="my-3"></v-divider>-->
              <!--              <v-btn depressed rounded text> Edit Account </v-btn>-->
              <!--              <v-divider class="my-3"></v-divider>-->
              <!--              <v-btn depressed rounded text> Change Password </v-btn>-->
              <v-divider class="my-3"></v-divider>
              <SwitchLangDialog>
                <template v-slot:activator>
                  <v-btn block rounded color="#AE2CA1">
                    {{ $_t("components.app.headerr.language.change_language") }}
                  </v-btn>
                </template>
              </SwitchLangDialog>
              <br />
              <ChangePassword>
                <template v-slot:activator>
                  <v-btn block rounded color="#AE2CA1">
                    {{ $_t("attribute.changePassword") }}
                  </v-btn>
                </template>
              </ChangePassword>
              <br />
              <v-btn block depressed rounded @click="logout" text>
                {{ $_t("components.app.header.logout") }}
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <Notifications />
    <v-main style="background-color: #343e59">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Notifications from "@/components/app/Notifications";
import SwitchLangDialog from "@/components/app/SwitchLangDialog";
import ChangePassword from "@/components/app/ChangePassword";

export default {
  name: "ReportLayout",
  components: { ChangePassword, SwitchLangDialog, Notifications },
  computed: {
    ...mapGetters({
      user: "authentication/user",
      role: "authentication/role",
      loading: "loading/is_loading",
      notifications: "notification/notifications",
    }),
    unreadNotifications() {
      if (!this.notifications.length) return [];
      return this.notifications.filter((n) => n.isRead === false);
    },
  },
  data: () => ({
    links: [
      { name: "Classes", to: { name: "report.class" } },
      { name: "Dashboard", to: { name: "report.main" } },
      { name: "ProgressStage", to: { name: "report.progressStage" } },
      { name: "Upcoming", to: { name: "report.upcoming" } },
      { name: "Notifications", to: { name: "report.notifications" } },
    ],
  }),
  methods: {
    logout() {
      this.$store.dispatch("authentication/logout");
      setTimeout(() => {
        window.location.reload();
      }, 200);
    },
    async getNotifications() {
      await this.$actions.getNotifications({ role: this.role, all: true });
    },
  },
  mounted() {
    this.getNotifications();
  },
};
</script>

<style scoped></style>
